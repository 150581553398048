<template>
  <div class="Wrap">
    <div class="main ispc">
      <div class="content" id="p_topBar">
        <!-- <div class="bg">
          <img
            :src="require(`@/assets/images/${uiSrc}/pcbg${i}.jpg`)"
            v-for="i in 4"
            :key="i"
            alt=""
          />
        </div> -->
        <div class="srollcontent linkTitle">
          <div class="logo">
            <img src="@/assets/images/pc/logo.png" alt="" />
          </div>
       
          <div  class="topTitleBg" >
          <video loop autoplay muted src="../assets/images/video/titleBg.mp4"></video>
          </div>
          <div class="topTitle">
            <img :src="require(`@/assets/images/${uiSrc}/topTitle.png`)" alt="" />
          </div>
          <div class="subscribeBox">
            <div class="subscribeBoxleft" :class="[{ subscribeBoxleftClose: !subShow }]">
              <img :src="require(`@/assets/images/${uiSrc}/infoBox.png`)" alt="" />

              <div class="subscribeContent">
                <div class="intel"><img src="@/assets/images/pc/intel.png" alt="" /></div>
                <div class="time">
                  <img src="@/assets/images/pc/time.png" alt="" />
                </div>
                <div class="infocontent">
                  <img
                    :src="require(`@/assets/images/${uiSrc}/infocontent.png`)"
                    alt=""
                  />
                </div>
                <div class="subscribeSuccess">
                  <img
                    :src="require(`@/assets/images/${uiSrc}/subscribeSuccess.png`)"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="subscribeBoxright" :class="{ subscribeBoxrightActive: !subShow }">
              <!-- https://live.juejin.cn/4354/future2022?mode=player -->
              <!-- 1111//live.byteoc.com/0081/0324 -->

              <!-- 直播 -->
              <div>
                <div class="liveTile">
                  <img :src="require(`@/assets/images/${uiSrc}/liveTitle.png`)" alt="" />
                </div>
                <iframe
                  v-if="isPc"
                  :src="ishttp + `//live.byteoc.com/0081/1222?platform=pc&mode=player`"
                  frameborder="0"
                  allowfullscreen="allowfullscreen"
                  webkitallowfullscreen="true"
                  class="iframe"
                  mozallowfullscreen="true"
                ></iframe>
              </div>

              <iframe
                v-if="!subShow && isPc"
                class="commentIframe"
                :src="
                  ishttp +
                  `//live.byteoc.com/0081/1222?platform=pc&mode=menu&menu=comment_imagetext`
                "
                allowfullscreen="true"
                frameborder="0"
              ></iframe>
            </div>
          </div>
          <!-- 介绍 -->
          <div class="introduceBox scrollContentItem">
            <div class="title linkTitle">
              <img :src="require(`@/assets/images/${uiSrc}/title1.png`)" alt="" />
            </div>
            <div class="introduceContent">
              <div class="introduceIconBox">
                <div
                  class="introduceIcon"
                  :class="{ introduceIconActive: introduceCount == i }"
                  v-for="i in 4"
                  :key="i"
                  @mouseover="viewIntroduce(i)"
                >
                  <div class="introduceIcon-1">
                    <img
                      :src="require(`@/assets/images/${uiSrc}/introduceIcon${i}-1.png`)"
                      alt=""
                    />
                    <div class="introduceIcon-2">
                      <img
                        :src="require(`@/assets/images/${uiSrc}/introduceIcon${i}-2.png`)"
                        alt=""
                      />

                      <div class="introduceIcon-3">
                        <img
                          :src="
                            require(`@/assets/images/${uiSrc}/introduceIcon${i}-3.png`)
                          "
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="introduceIconName">
                    <img
                      :src="require(`@/assets/images/${uiSrc}/introduceIcon${i}name.png`)"
                      alt=""
                    />
                    <img
                      :src="
                        require(`@/assets/images/${uiSrc}/introduceIcon${i}nameActive.png`)
                      "
                      alt=""
                    />
                  </div>

                  <div class="introduceTextBg">
                    <img
                      :src="require(`@/assets/images/${uiSrc}/introduceText${i}.png`)"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="introduceBg">
                <div
                  class="ellipse"
                  v-for="i in 4"
                  :key="i"
                  :style="{ animationDelay: `${(i - 1) * 1500}ms` }"
                >
                  <img
                    :src="require(`@/assets/images/${uiSrc}/introduceBg.png`)"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <!--嘉宾 -->
          <div class="scrollContentItem guestBox">
            <div class="title linkTitle">
              <img :src="require(`@/assets/images/${uiSrc}/title2.png`)" alt="" />
            </div>
            <div class="guestContent">
              <div class="guestTime">
                <div class="guestTimeCount">
                  <div
                    class="timeItem"
                    :class="{ timeItemActive: timeCount == item.id }"
                    v-for="item in timeList"
                    :key="item.id"
                  >
                    {{ item.time }}
                  </div>

                  <div class="scrollLine">
                    <img
                      :src="require(`@/assets/images/${uiSrc}/scrollLine.png`)"
                      alt=""
                    />
                    <div
                      class="scrollBar"
                      :class="{ scrollBarHaveBg: timeCount == 3 || timeCount == 2 }"
                    >
                      <img
                        :src="require(`@/assets/images/${uiSrc}/scrollBar.png`)"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="guestList">
                <div class="guestTitle">
                  <img :src="chapterTitle[showChapterTitleId - 1].path" alt="" />
                </div>
                <div
                  class="listItem"
                  v-for="(item, index) in guestArr.filter(
                    (item) => item.id == showChapterTitleId
                  )"
                  :key="index"
                  :timeCount="item.id"
                  :class="{ listItemActive: guestCount - 1 == index }"
                  @mouseover="guestItemClick(index + 1)"
                >
                  <div class="guestInfo">
                    <div>
                      <div class="title" v-html="item.title"></div>
                      <div class="titleDesc1" v-show="guestCount - 1 == index">
                        {{ item.titleDesc1 }}
                      </div>
                      <div class="titleDesc2" v-show="guestCount - 1 == index">
                        {{ item.titleDesc2 }}
                      </div>
                    </div>

                    <div class="peopleImg" v-show="guestCount - 1 == index">
                      <img :src="item.image" alt="" />
                    </div>
                    <div class="peopleBox" v-show="!item.onlyShowImg">
                      <div class="name">
                        {{ item.name }}
                      </div>
                      <div
                        class="company"
                        v-show="guestCount - 1 == index"
                        v-html="item.company"
                      ></div>
                      <div class="identity" v-show="guestCount - 1 == index">
                        {{ item.identity }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 方法 -->
          <div  class="methodBox scrollContentItem">
            <div class="title linkTitle">
              <img :src="require(`@/assets/images/${uiSrc}/title3.png`)" alt="" />
            </div>
            <div class="methodContent">
              <div class="methodNew">
                <img
                  :src="require(`@/assets/images/${uiSrc}/methodNewText.png`)"
                  alt=""
                />
              </div>
              <div class="methodRight">
                <img
                  v-if="!whiteBookBtnShow"
                  :src="require(`@/assets/images/${uiSrc}/methodRight.png`)"
                  alt=""
                />
                <img
                  v-else
                  :src="require(`@/assets/images/${uiSrc}/methodRightShow.png`)"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- 合作伙伴 -->
          <div class="friendBox scrollContentItem">
            <!-- <div class="title linkTitle">
              <img :src="require(`@/assets/images/${uiSrc}/title4.png`)" alt="" />
            </div> -->
            <div class="title linkTitle friendContent">
              <img :src="require(`@/assets/images/${uiSrc}/friendContent.png`)" alt="" />
            </div>
          </div>

          <div class="bottomFriendBg">
            <img :src="require(`@/assets/images/${uiSrc}/bottomFriendBg.png`)" alt="" />
            <div class="bottomFriend">
              <img :src="require(`@/assets/images/${uiSrc}/bottomFriend.png`)" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tagBox ispc">
      <div
        class="tagItem"
        v-for="(i, index) in tagArr"
        :key="index"
        @click="tagClick(index + 1)"
      >
        <div
          class="tag"
          :class="[
            `tag${index + 1}`,
            { tagActive: tagCount == index + 1 },
            { tagBlack: isBlack == 1 },
          ]"
        >
          {{ i }}
        </div>
      </div>
    </div>
    <div
      class="topBar ispc"
      :class="{ topBarBlack: isBlack == 1 }"
      :style="{ width: `${topBarWidth}px` }"
    >
      <div class="topLogo">
        <img :src="require('@/assets/images/' + uiSrc + '/topLogo.png')" alt="" />
      </div>
      <div class="topRight">
        <div class="topText">
          <img :src="require('@/assets/images/' + uiSrc + '/topText.png')" alt="" />
        </div>
        <div class="download">
          <img :src="require('@/assets/images/' + uiSrc + '/downloadBg.png')" alt="" />
          <a
            href="https://bytedance.larkoffice.com/file/HtZWbS8LGoPl7oxlXBqcTICunld"
            target="_blank"
            class="floatBox"
          ></a>
          <div class="downloadText">
            <!-- <img :src="require('@/assets/images/' + uiSrc + '/download.png')" alt="" /> -->
            资料下载
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!isPc"
      class="mobileMain ismobile"
      :style="{ overflow: mbTag != 1 ? 'hidden' : '' }"
    >
      <div class="mobileTopBar" :class="{ mobileTopBarBlack: isBlack == 1 }">
        <div class="logo">
          <img :src="require(`@/assets/images/${muiSrc}/logo.png`)" alt="" />
        </div>
      </div>
      <iframe
        v-if="!isPc"
        :src="ishttp + `//live.byteoc.com/0081/1222?platform=mobile&mode=player`"
        frameborder="0"
        allowfullscreen="allowfullscreen"
        webkitallowfullscreen="true"
        class="iframe"
        mozallowfullscreen="true"
      ></iframe>
      <div
        class="mbcontent"
        :class="[{ mbcontenttagother: mbTag != 1 }, { mbcontentBlack: isBlack == 1 }]"
      >
        <div class="initroduceTagBox">
          <div class="initroduceTag" v-for="i in 3" :key="i" @click="introduceClick(i)">
            <img
              :style="{ visibility: i == mbTag ? 'hidden' : 'visible' }"
              :src="require(`@/assets/images/${muiSrc}/mbtagBg.png`)"
              alt=""
            />
            <div class="selectBg" v-show="i == mbTag">
              <img :src="require(`@/assets/images/${muiSrc}/mbtagBgActive.png`)" alt="" />
            </div>
            <div class="tagtitleText">
              <!-- <img :src="require(`@/assets/images/${muiSrc}/tagtitle${i}.png`)" alt="" /> -->
              <div v-if="i == 1">大会流程</div>
              <div v-if="i == 2">评论互动</div>
              <div v-if="i == 3">嘉宾金句</div>
            </div>
          </div>
        </div>
        <!-- 介绍 -->
        <div class="itemCountent introduceiContent" v-show="mbTag == 1">
          <div class="titleBox">
            <img :src="require(`@/assets/images/${muiSrc}/titleText1.png`)" alt="" />
          </div>
          <div class="introduceText">
            <img :src="require(`@/assets/images/${muiSrc}/introduceText.png`)" alt="" />
          </div>
        </div>
        <iframe
          class="miframe"
          v-if="!isPc"
          :class="{ mobileComment: mbTag == 2 }"
          :src="
            ishttp +
            `//live.byteoc.com/0081/1222?platform=mobile&mode=comment&background=transparent`
          "
          allowfullscreen="true"
          frameborder="0"
        ></iframe>
        <iframe
          class="miframe"
          v-if="!isPc"
          :class="{ mobileImagetext: mbTag == 3 }"
          :src="
            ishttp +
            `//live.byteoc.com/0081/1222?platform=mobile&mode=imagetext&background=transparent`
          "
          allowfullscreen="true"
          frameborder="0"
        ></iframe>
        <div v-show="mbTag == 1" class="itemCountent">
          <div class="titleBox">
            <img :src="require(`@/assets/images/${muiSrc}/titleText2.png`)" alt="" />
          </div>

          <div class="introduceIconBox">
            <div
              class="introduceIcon"
              :class="{ introduceIconActive: introduceCount == i }"
              v-for="i in 4"
              :key="i"
              @click="introduceCount = i"
            >
              <div class="introduceIcon-1">
                <img
                  :src="require(`@/assets/images/${uiSrc}/introduceIcon${i}-1.png`)"
                  alt=""
                />
                <div class="introduceIcon-2">
                  <img
                    :src="require(`@/assets/images/${uiSrc}/introduceIcon${i}-2.png`)"
                    alt=""
                  />

                  <div class="introduceIcon-3">
                    <img
                      :src="require(`@/assets/images/${uiSrc}/introduceIcon${i}-3.png`)"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="introduceIconName">
                <img
                  :src="require(`@/assets/images/${uiSrc}/introduceIcon${i}name.png`)"
                  alt=""
                />
                <img
                  :src="
                    require(`@/assets/images/${uiSrc}/introduceIcon${i}nameActive.png`)
                  "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div v-for="i in 4" :key="i + 'a'">
            <div class="introduceTextBg" v-show="introduceCount == i">
              <img
                :src="require(`@/assets/images/${muiSrc}/introduceText${i}.png`)"
                alt=""
              />
            </div>
          </div>
        </div>
        <!-- 盛典日程 -->
        <div v-show="mbTag == 1" class="itemCountent mbtimeContent">
          <div class="titleBox">
            <img :src="require(`@/assets/images/${muiSrc}/titleText3.png`)" alt="" />
          </div>
          <div class="timeline">
            <div
              class="timeItem"
              @click="timeClick(1)"
              :class="{ timeItemActive: showChapterTitleId == 1 }"
            >
              篇章一
            </div>
            <div
              class="timeItem"
              @click="timeClick(2)"
              :class="{ timeItemActive: showChapterTitleId == 2 }"
            >
              篇章二
            </div>
            <div
              class="timeItem"
              @click="timeClick(3)"
              :class="{ timeItemActive: showChapterTitleId == 3 }"
            >
              篇章三
            </div>
            <div
              class="timeItem"
              @click="timeClick(4)"
              :class="{ timeItemActive: showChapterTitleId == 4 }"
            >
              篇章四
            </div>
          </div>
          <div class="scrollLine">
            <img :src="require(`@/assets/images/${muiSrc}/scrollLine.png`)" alt="" />
            <div
              class="scrollBar"
              :style="{ left: `${(showChapterTitleId - 1) * 32}%` }"
              :class="{
                scrollBarHaveBg: showChapterTitleId == 2 || showChapterTitleId == 3,
              }"
            >
              <img :src="require(`@/assets/images/${muiSrc}/scrollBar.png`)" alt="" />
            </div>
          </div>
          <div class="mbGuestBox">
            <div class="mbGuestTitle">
              <img :src="mbGuestArr[showChapterTitleId - 1].chapterTitle" alt="" />
            </div>
            <div
              class="guestItem"
              v-for="(item, index) in mbGuestArr[showChapterTitleId - 1].peopleArr"
              :key="index"
            >
              <img :src="item.path" alt="" />
            </div>
          </div>
        </div>
        <!-- 方法 -->
        <div v-show=" mbTag == 1" class="itemCountent mbmethodContent">
          <div class="titleBox">
            <img :src="require(`@/assets/images/${muiSrc}/titleText4.png`)" alt="" />
          </div>
          <div class="mbmethod">
            <img :src="require(`@/assets/images/${muiSrc}/mbmethod.png`)" alt="" />
            <div class="mbmethodText" v-if="!whiteBookBtnShow">敬请期待...</div>
            <div class="mbmethodText mbmethodTextLink" v-else>
              点击查看
              <a
                class="floatBox"
                target="_blank"
                href="https://trendinsight.oceanengine.com/arithmetic-report/detail/1038"
              ></a>
            </div>
          </div>
        </div>
        <!-- 合作 -->
        <div v-show="mbTag == 1" class="itemCountent mbfriendContent">
          <!-- <div class="titleBox">
            <img :src="require(`@/assets/images/${muiSrc}/titleText5.png`)" alt="" />
          </div> -->
          <div class="friendBox">
            <img :src="require(`@/assets/images/${muiSrc}/friend.png`)" alt="" />
          </div>
        </div>
        <div v-show="mbTag == 1" class="itemCountent mbfriendContent">
          <div class="titleBox">
            <img :src="require(`@/assets/images/${muiSrc}/titleText6.png`)" alt="" />
          </div>
          <div class="friendBottom">
            <img :src="require(`@/assets/images/${muiSrc}/friendBottom.png`)" alt="" />
          </div>
          <div class="friendBottomBg">
            <img :src="require(`@/assets/images/${uiSrc}/bottomFriendBg.png`)" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventClick, isPC } from "@/util/util";
// @ is an alias to /src
export default {
  components: {},
  data() {
    return {
      uiSrc: "pcBlack",
      muiSrc: "mobileBlack",
      isBlack: 1, //1黑色版本
      whiteBookBtnShow: false, // 是否展示白皮书
      ishttp: location.protocol,
      subShow: false, //展示评论区的
      tagCount: 1, //当前选中的tag
      methodCount: 1, //当前选中的方法
      guestCount: 1, //选中的嘉宾
      chaptCount: 1,
      introduceCount: 1, // 显示第几个介绍
      tagArr: ["大会介绍", "亮点介绍", "大会议程", "白皮书发布", "合作伙伴"], //导航标签
      guestArr: [
        {
          id: 1,
          title: "开场致辞",
          name: "杜国臣",
          company: "商务部研究院<br/>电商所所长",

          image: require("@/assets/images/pcBlack/people1-a.png"),
        },

        {
          id: 1,
          title: "多元共振，全面赋能长效经营",
          name: "李 炜",
          company: "抖音生活服务<br/>到店业务副总裁 ",
          image: require("@/assets/images/pcBlack/people1-b.png"),
        },
        {
          id: 2,
          // title: "多维布局，飞轮驱动生意增长<br/>初心滚烫，聚力共赴闪耀星河",
          title: "初心滚烫，聚力共赴闪耀星河",
          name: "陈琦",
          company: "抖音生活服务",
          identity: "综合行业业务负责人",
          image: require("@/assets/images/pcBlack/people2-a.png"),
        },
        {
          id: 2,
          title: "前瞻趋势，数据助力科学营销",
          name: "林文斌",
          company: "巨量引擎商业<br/>分析负责人、<br/>巨量引擎城市<br/>研究院院长",
          image: require("@/assets/images/pcBlack/people2-b.png"),
        },
        {
          id: 3,
          title: "综合行业年度星光盛典<br/>综合行业优秀伙伴分享",
          image: require("@/assets/images/pcBlack/people4-b.png"),
          onlyShowImg: true,
        },
        {
          id: 3,
          title: "零售跃迁，加速商超模式焕新",
          // titleDesc1: "年度星光盛典-进场零售行业",
          // titleDesc2: "星耀品牌分享时刻",
          name: "曹世如",
          company: "成都红旗连锁股份有限公司",
          identity: "董事长",
          image: require("@/assets/images/pcBlack/people3-c.png"),
        },

        {
          id: 3,
          title: "用心经营，向新而行美力共生",
          // titleDesc1: "年度星光盛典-丽人行业",
          // titleDesc2: "星耀品牌分享时刻",
          name: "王静",
          company: "美丽田园",
          identity: "线上业务负责人 ",
          image: require("@/assets/images/pcBlack/people3-a.png"),
        },
        {
          id: 3,
          title: "芯启智变，科技引领产业升级",
          name: "何君瑜",
          company: "英特尔智慧城市<br/>市场总监",
          image: require("@/assets/images/pcBlack/people3-b.png"),
        },
        {
          id: 3,
          title: "经营有术，品效合一全域增长",
          // titleDesc1: "年度星光盛典-休闲娱乐行业",
          // titleDesc2: "星耀品牌分享时刻",
          name: "杨东军",
          company: "远元集团副总经理",
          // identity: "总经理",
          image: require("@/assets/images/pcBlack/people3-d.png"),
        },
        {
          id: 3,
          title: "兴趣赋能，创新激发生意增长",
          // titleDesc1: "年度星光盛典-Life Event行业",
          // titleDesc2: "星耀品牌分享时刻",
          name: "米若義",
          company: "万达电影新媒体中心",
          identity: "总经理",
          image: require("@/assets/images/pcBlack/people3-e.png"),
        },
        {
          id: 3,
          title: "内容提效，营销焕活品牌新生",
          // titleDesc1: "年度星光盛典-优秀机构 ",
          // titleDesc2: "星耀机构分享时刻",
          name: "徐刚",
          company: "交个朋友抖音事业部<br/>高级商务负责人 ",
          image: require("@/assets/images/pcBlack/people3-f.png"),
        },
        {
          id: 3,
          title: "合力同行，生态助推商服共赢",
          // titleDesc1: "优秀服务商年度星光盛典",
          // titleDesc2: "星耀服务商分享会",
          name: "许迪",
          company: "深圳市今晟传媒",
          identity: "有限公司 CEO",
          image: require("@/assets/images/pcBlack/people3-g.png"),
        },

        {
          id: 4,
          title: "「共赴闪耀星河」 启航仪式<br/><span>&nbsp;&nbsp;</span>伙伴面对面",
          onlyShowImg: true,
          image: require("@/assets/images/pcBlack/people4-b.png"),
        },
      ],
      timeList: [
        { id: 1, time: "14:00-14:15" },
        { id: 2, time: "14:15-15:05" },
        { id: 3, time: "15:05-17:10" },
        { id: 4, time: "17:10-17:20" },
      ],
      chapterTitle: [
        { id: 1, path: require("@/assets/images/pcBlack/chapterTitle1.png") },
        { id: 2, path: require("@/assets/images/pcBlack/chapterTitle2.png") },
        { id: 3, path: require("@/assets/images/pcBlack/chapterTitle3.png") },
        { id: 4, path: require("@/assets/images/pcBlack/chapterTitle4.png") },
      ],
      mbGuestArr: [
        {
          id: 1,
          chapterTitle: require("@/assets/images/mobileBlack/chapterTitle1.png"),
          peopleArr: [
            { path: require("@/assets/images/mobileBlack/guestPeople1-1.png") },
            { path: require("@/assets/images/mobileBlack/guestPeople1-2.png") },
          ],
        },
        {
          id: 2,
          chapterTitle: require("@/assets/images/mobileBlack/chapterTitle2.png"),
          peopleArr: [
            { path: require("@/assets/images/mobileBlack/guestPeople2-1.png") },
            { path: require("@/assets/images/mobileBlack/guestPeople2-2.png") },
          ],
        },
        {
          id: 3,
          chapterTitle: require("@/assets/images/mobileBlack/chapterTitle3.png"),
          peopleArr: [
            { path: require("@/assets/images/mobileBlack/guestPeople3-0.png") },
            { path: require("@/assets/images/mobileBlack/guestPeople3-1.png") },
            { path: require("@/assets/images/mobileBlack/guestPeople3-2.png") },
            { path: require("@/assets/images/mobileBlack/guestPeople3-3.png") },
            { path: require("@/assets/images/mobileBlack/guestPeople3-4.png") },
            { path: require("@/assets/images/mobileBlack/guestPeople3-5.png") },
            { path: require("@/assets/images/mobileBlack/guestPeople3-7.png") },
            { path: require("@/assets/images/mobileBlack/guestPeople3-6.png") },
          ],
        },
        {
          id: 4,
          chapterTitle: require("@/assets/images/mobileBlack/chapterTitle4.png"),
          peopleArr: [
            { path: require("@/assets/images/mobileBlack/guestPeople4-1.png") },
          ],
        },
      ], // 手机端的嘉宾列表
      showChapterTitleId: 1,
      timeCount: 1,
      viewGuestArr: [],
      whiteBookShowTimer: null,
      chapterTimer: null,
      mbTag: 1, // 手机选中的标签
      topBarWidth: "",
      isBottom: false, //判断嘉宾是否滚到低了
      isPc: "",
    };
  },
  methods: {
    tagClick(val) {
      //   获取所有锚点元素
      const contents = document.querySelectorAll(".linkTitle");
      const topBar = document.querySelector(".topBar");
      const contentsOffsetTop = [];
      contents.forEach((item) => {
        contentsOffsetTop.push(item.offsetTop);
      });
      const main = document.getElementsByClassName("main")[0];

      main.scrollTo({
        top: contentsOffsetTop[val - 1] - topBar.clientHeight,
        behavior: "smooth",
      });
      setTimeout(() => {
        this.tagCount = val;
      }, 200);
      eventClick(`导航标签,标签${val}`);
    },
    viewIntroduce(i){
      if(this.introduceCount == i)return
      this.introduceCount = i
      eventClick(`查看介绍${i}`)
    },
    scorllAnchor() {
      //  获取当前页面滚到哪了
      const main = document.getElementsByClassName("main")[0] || "";
      const scrollTop = main.scrollTop;
      const screenHeight = window.screen.height;
      //   获取所有锚点元素
      const contents = document.querySelectorAll(".linkTitle");
      //  获取锚点元素的offsetTop值，并收集在一个数组
      const contentsOffsetTop = [];
      contents.forEach((item) => {
        contentsOffsetTop.push(item.offsetTop);
      });
      //  遍历锚点元素的offsetTop值
      for (let i = 0; i < contentsOffsetTop.length; i++) {
        if (scrollTop >= contentsOffsetTop[i] - screenHeight / 4) {
          this.tagCount = i + 1;
        }
      }
      // 获取所有嘉宾列表
      const guestListArr = document.querySelectorAll(".listItem");
      const scrollBar = document.querySelector(".scrollBar");
      const topBarHeight = document.querySelector(".topBar").clientHeight;
      guestListArr.forEach((item, index) => {
        if (
          item.getBoundingClientRect().top <= topBarHeight + 250 &&
          item.getBoundingClientRect().bottom >= 0
        ) {
          // this.timeCount = item.attributes.timeCount.value;
          this.guestItemClick(index + 1);
          //设置滚动点位置
          // if (this.timeCount == 1) {
          //   scrollBar.style.top = "2%";
          // } else {
          //   scrollBar.style.top = `${(this.timeCount - 1) * 33}%`;
          // }
        }
      });
      const guestTime = document.querySelector(".guestTimeCount");
      const guestTitle = document.querySelector(".guestTitle");
      const titleLeft = document.querySelectorAll(".linkTitle")[1].getBoundingClientRect()
        .left;

      if (guestTitle.getBoundingClientRect().top <= 0) {
        guestTime.style.position = "fixed";
        guestTime.style.top = "10%";
        guestTime.style.left = titleLeft + "px";

        let offset =
          guestListArr[guestListArr.length - 1].getBoundingClientRect().top -
          guestTime.clientHeight;
        if (offset <= 0) {
          guestTime.style.transform = `translateY(${offset}px)`;
        }
      } else {
        guestTime.style.position = "";
        guestTime.style.top = "";
        guestTime.style.left = "";
        guestTime.style.bottom = "";
        guestTime.style.transform = ``;
      }
    },
    methodClick(val) {
      if (this.methodCount == val) return;
      this.methodCount = val;
    },
    guestItemClick(val) {
      this.guestCount = val;
    },
    timeClick(val) {
      return;
      this.showChapterTitleId = val;
      eventClick(`点击篇章${val}`);
    },
    showWhiteBook() {
      let time = "2023-12-22 15:00";
      this.whiteBookShowTimer = setInterval(() => {
        if (this.compareDates(time)) {
          this.whiteBookBtnShow = true;
          clearTimeout(this.whiteBookShowTimer)
        }
      }, 1000);
    },

    showChapter() {
      let timeArr = [
        { id: 1, date: "2023-12-22 14:00" },
        { id: 2, date: "2023-12-22 14:15" },
        { id: 3, date: "2023-12-22 15:05" },
        { id: 4, date: "2023-12-22 17:10" },
      ];
      
      this.chapterTimer = setInterval(() => {
        for (let item of timeArr) {
          if (this.compareDates(item.date)) {
             this.showChapterTitleId = item.id;
          }
        }
        if (this.isPc) {
          const scrollBar = document.querySelector(".scrollBar");
          setTimeout(() => {
            if (this.showChapterTitleId == 1) {
            scrollBar.style.top = "2%";
          } else {
            scrollBar.style.top = `${(this.showChapterTitleId - 1) * 33}%`;
          }
          }, 100);
          this.timeCount = this.showChapterTitleId;
        }
      }, 1000);
    },
    compareDates(dateStr) {
      const date = new Date();
      const compareDate = new Date(dateStr);

      if (date > compareDate) {
        return true;
      } else {
        return false;
      }
    },
    // mb
    introduceClick(val) {
      if (this.mbTag == val) return;
      this.mbTag = val;
      eventClick(`手机端介绍标签${val}点击`);
    },
  },
  mounted() {
    // 控制白皮书
    this.showWhiteBook();
    // 控制嘉宾模块
    this.showChapter();
    // 监听滚动事件
    window.addEventListener("scroll", this.scorllAnchor, true);

    // 判断是否开始直播了

    this.isPc = isPC();
    window.onresize = () => {
      return (() => {
        this.$nextTick(() => {
          if (!isPC()) return;
          var child = document.querySelector(".Wrap") || "";
          var pTopBar = document.querySelector("#p_topBar");
          var scrollbarWidth = child.parentNode.offsetWidth - pTopBar.offsetWidth;
          this.topBarWidth = child.parentNode.offsetWidth - scrollbarWidth;
        });
      })();
    };
    setTimeout(() => {
      this.$nextTick(window.onresize());
    }, 1000);
  },
  updated() {
    this.$nextTick(window.onresize());
  },
  destroyed() {
    window.removeEventListener("scroll", this.scorllAnchor, true);
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/css/home.less";
</style>
