export function eventClick( desc) {
    console.log('11')
    window._hmt.push(['_trackEvent', '按钮', '点击', desc]);
}

export function isPC() {
 
    var userAgentInfo = navigator.userAgent;
     
    var Agents = ["Android", "iPhone",
     
    "SymbianOS", "Windows Phone",
     
    "iPad", "iPod"];
     
    var flagPc = true;
     
    for (var v = 0; v < Agents.length; v++) {
     
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
     
    flagPc = false;
     
    break;
     
    }
     
    }
     
    return flagPc;
     
    }

    export function lazyLoading(imgs, callback) {
        if (!imgs || imgs.length == 0) return;
         let loader = new createjs.LoadQueue(false);  
        loader.setMaxConnections(5);
        loader.loadManifest(imgs);
        loader.on("progress", callback.progress);
        // loader.on("complete", callback.complete);
        // loader.on("fileload", callback.fileload);
    }
     



 